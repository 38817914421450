(function () {
  if (!DS.Config.ADRUM_ENABLED) {
    return;
  }

  const percentageToMonitor = adrumSettings.percentageToMonitor; // eslint-disable-line no-undef
  const cookiePrefix = 'adrum_throttle';
  const expireAfterXHours = 1;
  const adrumKey = DS.Config.ADRUM_KEY;

  const setCookie = (cname, cvalue) => {
    const exhours = expireAfterXHours;
    const d = new Date();
    d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = `${cookiePrefix}.${cname}=${cvalue};${expires};path=/`;
  };

  const getCookie = (cname) => {
    const name = `${cookiePrefix}.${cname}=`;
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';').map((c) => c.trim());
    const c = ca.find((cookie) => cookie.startsWith(name));
    return c ? c.substring(name.length, c.length) : '';
  };

  const getRandomInt = (min, max) => Math.floor(Math.random() * (max)) + min;

  const extractUserData = () => {
    const cookies = document.cookie.split(';');
    let colen;
    cookies.forEach((item) => {
      const cookie = item.trim();
      if (cookie.indexOf('DANSKESPIL_ENSIGHTEN_PRIVACY_Marketing=') === 0) {
        colen = cookie.substring('DANSKESPIL_ENSIGHTEN_PRIVACY_Marketing='.length);
      }
    });

    return {
      userData: {
        marketing: colen,
        regiontheme: adrumSettings.regionTheme // eslint-disable-line no-undef
      }
    };
  };

  const openBetDoesFetchOverride = () => {
    // Openbet has some logic, where they override window.fetch  in some cases
    const userAgentStr = window.navigator.userAgent;
    const userAgentIncludes = (str) => userAgentStr.toLowerCase().includes(str);
    const isSafariForIOSDevice = () => userAgentIncludes('iphone') || userAgentIncludes('ipad');
    const version = parseInt(userAgentStr.slice(userAgentStr.toLowerCase().indexOf('version/') + 9).split(' ')[0], 10);
    const isOldSafari = () => userAgentIncludes('safari') && version <= 11;

    if (isSafariForIOSDevice() || isOldSafari()) {
      return true;
    }

    return false;
  };

  window['adrum-start-time'] = new Date().getTime();

  ((config) => {
    let adrumEnabled = getCookie('adrumEnabled');
    if (adrumEnabled === '') {
      // Cookie not set
      const i = getRandomInt(1, 100);
      adrumEnabled = i <= percentageToMonitor ? '1' : '0';
    }

    // Set or bump cookie
    setCookie('adrumEnabled', adrumEnabled);

    console.log('Adrum ' + (adrumEnabled === '1' ? 'enabled' : 'disabled'));

    config.appKey = adrumEnabled === '1' ? adrumKey : 'no-key';
    config.adrumExtUrlHttp = 'http://cdn.appdynamics.com';
    config.adrumExtUrlHttps = 'https://cdn.appdynamics.com';
    config.beaconUrlHttp = 'http://fra-col.eum-appdynamics.com';
    config.beaconUrlHttps = 'https://fra-col.eum-appdynamics.com';
    config.resTiming = {
      bufSize: 200,
      clearResTimingOnBeaconSend: true
    };
    config.maxUrlLength = 512;

    // For fixing issues related to Openbet/Tips SPA:
    config.isZonePromise = true;
    config.spa = {
      spa2: true // eslint-disable-line quote-props
    };

    config.fetch = DS.Config.ADRUM_FETCH_ENABLED && !openBetDoesFetchOverride();

    ((info) => {
      info.PageView = extractUserData;
    })(config.userEventInfo || (config.userEventInfo = {}));

  })(window['adrum-config'] || (window['adrum-config'] = {}));

})();
